import { all, call, put, takeLatest, select } from 'typed-redux-saga/macro';

import MultiTenantService, {
  extractResponseData,
} from 'src/services/MultiTenantService';
import { API_DATE_FORMAT, nowUTC } from 'src/utils/timeUtils';
import { actions as gmActions } from 'src/redux/data/groupManager';
import { actions as subscriberActions } from 'src/redux/data/subscribers';
import { actions as userActions } from 'src/redux/data/user';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { actions } from './slice';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { getAlertLogsUpdated } from 'src/utils/alertHelpers';
import { AlertLog } from 'src/types/alerts';
import { filterDuplicates } from 'src/utils/dataUtils';
import { UUID } from 'src/types/utility';

function* onPageMount() {
  yield* all([
    put(subscriberActions.fetchSubscribersList()),
    put(userActions.fetchExistentUsers()),
    put(gmActions.fetchSubtenantData({ dataKey: GM_DATA_KEYS.SUBSCRIBERS })),
  ]);
}

function* fetchSubtenantAlertLog(
  action: ReturnType<typeof actions.fetchSubtenantAlertLog>,
) {
  try {
    const { subtenantId, currentPage, pageSize, startDateTime, endDateTime } =
      action.payload;

    const endDateAndTime = endDateTime ?? nowUTC();
    const startDateAndTime = startDateTime ?? endDateAndTime.subtract(1, 'day');

    const response = yield* call(
      MultiTenantService.fetchMultitenantAlertLog,
      startDateAndTime.format(API_DATE_FORMAT),
      endDateAndTime.format(API_DATE_FORMAT),
      [subtenantId],
      [],
      currentPage,
      pageSize,
    );

    const tenantsData = extractResponseData(response);
    const subtenantAlertLogs = tenantsData[subtenantId]?.alertLogs || [];
    // update long out of bed triggerValue
    const timezone = yield* select(loggedInUserSelectors.getUserTenantTimezone);
    const updatedSubtenantAlertLogs = getAlertLogsUpdated(
      subtenantAlertLogs,
      timezone,
    ) as AlertLog[];
    const alertLogPatientIds = filterDuplicates(
      subtenantAlertLogs.reduce((acc: UUID[], alertLog) => {
        return [...acc, alertLog.patientId || ''];
      }, []),
    );
    const alertLogDeviceIds = filterDuplicates(
      subtenantAlertLogs.reduce((acc: UUID[], alertLog) => {
        return [...acc, alertLog.deviceId || ''];
      }, []),
    );
    yield* all([
      put(gmActions.fetchMultitenantPatients(alertLogPatientIds)),
      put(
        gmActions.fetchSubtenantData({
          dataKey: GM_DATA_KEYS.ROOMS,
          payload: alertLogDeviceIds,
        }),
      ),
    ]);
    const subtenantAlertLogsMetadata = tenantsData[subtenantId]?.metadata?.page;
    yield* put(
      actions.fetchSubtenantAlertLogSuccess({
        subtenantId,
        alerts: updatedSubtenantAlertLogs,
      }),
    );
    yield* put(
      actions.fetchSubtenantAlertLogMetadataSuccess(subtenantAlertLogsMetadata),
    );
  } catch (e) {
    console.error(`error in fetchSubtenantAlertLog`, e);
    actions.fetchSubtenantAlertLogFailed();
  }
}

function* fetchSubtenantDisconnectionOverview(
  action: ReturnType<typeof actions.fetchSubtenantDisconnectionOverview>,
) {
  try {
    const {
      subtenantId,
      continuousDisconnectionSeconds,
      currentPage,
      pageSize,
    } = action.payload;
    const response = yield* call(
      MultiTenantService.fetchMultitenantDisconnectionOverview,
      continuousDisconnectionSeconds,
      [subtenantId],
      currentPage,
      pageSize,
    );

    const tenantsData = extractResponseData(response);
    const subtenantResponse =
      tenantsData[subtenantId]?.disconnectedDevices || [];
    const subtenantMetadataResponse = tenantsData[subtenantId]?.metadata.page;
    const DisconnectionOverviewDeviceIds = subtenantResponse.map(
      elem => elem.manufacturerId,
    );

    const payloadSize = 100;
    for (
      let i = 0;
      i < DisconnectionOverviewDeviceIds.length;
      i += payloadSize
    ) {
      const payloadChunk = DisconnectionOverviewDeviceIds.slice(
        i,
        i + payloadSize,
      );
      yield* put(
        gmActions.fetchSubtenantData({
          dataKey: GM_DATA_KEYS.ROOMS,
          payload: payloadChunk,
        }),
      );
    }

    yield* put(
      actions.fetchSubtenantDisconnectionOverviewSuccess({
        subtenantId,
        disconnectionOverview: subtenantResponse,
        metaData: subtenantMetadataResponse ?? {
          totalResults: 0,
          page: 0,
          limit: 0,
        },
      }),
    );
  } catch (e) {
    console.error(`error in fetchSubtenantDisconnectionOverview`, e);
    actions.fetchSubtenantDisconnectionOverviewFailed();
  }
}

export default function* watchNursesStationActions() {
  yield* all([
    takeLatest(actions.gmDashboardPageMounted, onPageMount),
    takeLatest(actions.fetchSubtenantAlertLog, fetchSubtenantAlertLog),
    takeLatest(
      actions.fetchSubtenantDisconnectionOverview,
      fetchSubtenantDisconnectionOverview,
    ),
  ]);
}
