import React from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { MenuProps } from 'antd';

import AppConfig from 'src/config/AppConfig';
import userHeaderIcon from '../resources/user-header-icon.svg';
import {
  StyledDropdown,
  DropdownItem,
  StyledLink,
  StyledUser,
  UserDetails,
  StyledRow,
  StyledText,
  StyledSmallText,
  StyledAvatar,
} from './styled';
import { EXTERNAL_LINKS } from 'src/utils/constants';
import {
  displayInTimezone,
  US_12H_DATE_AND_TIME_FORMAT,
} from 'src/utils/timeUtils';
import dayjs from 'dayjs';

type Props = {
  loggedInUserName: string;
  userTenantName: string;
  userTenantTimezone: string;
  logout: ActionCreatorWithoutPayload<'auth/logout'>;
  intl: IntlShape;
};

const UserMenu = ({
  loggedInUserName,
  userTenantName,
  userTenantTimezone,
  logout,
  intl,
}: Props): JSX.Element => {
  const getSubMenu = (
    userManuals: {
      fileName: string;
      displayName: { defaultMessage: string };
    }[],
  ) =>
    userManuals.map(userManual => ({
      key: userManual.fileName,
      label: (
        <DropdownItem key={userManual.fileName}>
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            href={`${AppConfig.MANUALS_BUCKET_LINK}/${userManual.fileName}`}
          >
            {intl.formatMessage(userManual.displayName)}
          </StyledLink>
        </DropdownItem>
      ),
    }));

  const items: MenuProps['items'] = [
    {
      key: 'manuals',
      label: (
        <DropdownItem>
          {intl.formatMessage(messages.manualsSubMenuTitle)}
        </DropdownItem>
      ),
      children: manuals.map(manual => ({
        key: intl.formatMessage(manual.displayName),
        label: manual.fileName ? (
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            href={`${AppConfig.MANUALS_BUCKET_LINK}/${manual.fileName}`}
          >
            {intl.formatMessage(manual.displayName)}
          </StyledLink>
        ) : (
          <DropdownItem key={intl.formatMessage(manual.displayName)}>
            {intl.formatMessage(manual.displayName)}
          </DropdownItem>
        ),
        children:
          intl.formatMessage(manual.displayName) ===
          intl.formatMessage(messages.userManual)
            ? getSubMenu(userManuals)
            : null,
      })),
    },
    {
      key: 'contact',
      label: (
        <DropdownItem
          onClick={() => {
            window.location.href = 'mailto:support@neteera.com';
          }}
        >
          {intl.formatMessage(messages.contact)}
        </DropdownItem>
      ),
    },
    {
      key: 'privacyPolicy',
      label: (
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href={EXTERNAL_LINKS.PRIVACY_POLICY}
        >
          {intl.formatMessage(messages.privacyPolicy)}
        </StyledLink>
      ),
    },
    {
      key: 'logout',
      label: (
        <DropdownItem onClick={() => logout()} data-cy="logout-btn">
          {intl.formatMessage(messages.logout)}
        </DropdownItem>
      ),
    },
  ];

  return (
    <StyledDropdown menu={{ items }}>
      <StyledUser data-cy="header-user-menu">
        <UserDetails>
          <StyledRow>
            <StyledText>{`${loggedInUserName},`}</StyledText>
            <StyledText>{userTenantName}</StyledText>
          </StyledRow>
          <StyledRow>
            <StyledSmallText>
              {displayInTimezone(
                dayjs().format(),
                userTenantTimezone,
                US_12H_DATE_AND_TIME_FORMAT,
              )}
            </StyledSmallText>
          </StyledRow>
        </UserDetails>
        <StyledAvatar icon={<img src={userHeaderIcon} alt="logged-user" />} />
      </StyledUser>
    </StyledDropdown>
  );
};

const messages = defineMessages({
  logout: {
    defaultMessage: 'Logout',
  },
  contact: {
    defaultMessage: 'Contact Support',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
  },
  manualsSubMenuTitle: {
    defaultMessage: 'Manuals',
  },
  userManual: {
    defaultMessage: 'User Manual',
  },
  mainManual: {
    defaultMessage: 'Main',
  },
  tenantAdminManual: {
    defaultMessage: 'Tenant Admin',
  },
  alarmsManual: {
    defaultMessage: 'Alarms',
  },
  installationManual: {
    defaultMessage: 'Installation',
  },
  healthProfessionalManual: {
    defaultMessage: 'Health Professional',
  },
  multiTenantManual: {
    defaultMessage: 'Multi-Tenant',
  },
  deviceActivation: {
    defaultMessage: 'Device Activation Manual',
  },
  neteeraSystemSpecification: {
    defaultMessage: 'Neteera System Specification',
  },
});

const manuals = [
  {
    displayName: messages.userManual,
  },
  {
    fileName: 'device-activation-manual.pdf',
    displayName: messages.deviceActivation,
  },
  {
    fileName: 'neteera-system-specification.pdf',
    displayName: messages.neteeraSystemSpecification,
  },
];

const userManuals = [
  {
    fileName: 'IFU-0002+130H-Plus+IFU+Main+Rev.+1.0+-+REDLINED.pdf',
    displayName: messages.mainManual,
  },
  {
    fileName: 'IFU-0006+130H-Plus+IFU+Tenant+Admin+Rev+1.0.pdf',
    displayName: messages.tenantAdminManual,
  },
  {
    fileName: 'IFU-0003+130H-Plus+IFU+Alarms+(Alerts)+Rev+1.0+-+REDLINED.pdf',
    displayName: messages.alarmsManual,
  },
  {
    fileName: 'IFU-0005+130H-Plus+IFU+Installation+Rev+1.0.pdf',
    displayName: messages.installationManual,
  },
  {
    fileName: 'IFU-0004+130H-Plus+IFU+Health+Professional+Rev+1.0.pdf',
    displayName: messages.healthProfessionalManual,
  },
  {
    fileName: 'IFU-0007+130H-Plus+IFU+Multi-Tenant+Admin+Rev+1.0.pdf',
    displayName: messages.multiTenantManual,
  },
];
export default injectIntl(UserMenu);
