import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalTitle = styled.div`
  font-weight: 800;
  font-size: 1.3rem;
  color: #252525;
`;

export const CancelBtn = styled.div<{ isDisabled?: boolean }>`
  padding: 0.5rem 1.25rem;
  font-weight: 500;
  font-size: 0.7rem;
  color: #767881;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;

export const SaveBtn = styled.div<{ isDisabled?: boolean }>`
  background: #304e64;
  padding: 0.5rem 2.25rem;
  border-radius: 0.9rem;
  font-weight: 500;
  font-size: 0.7rem;
  color: #ffffff;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding-bottom: 0.5rem;
    border: none;
  }
  .ant-modal-body {
    font-size: 0.7rem;
  }
  .ant-modal-footer {
    border: none;
    button {
      padding: 0 0.7rem;
      font-size: 0.7rem;
      height: 2rem;
    }
  }

  .ant-modal-close {
    width: 0.9rem;
    height: 0.9rem;
    &:hover {
      background: none;
    }
  }
`;
